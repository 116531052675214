/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { start } from '../lib/saga-promise';
import { kenteiRedirect } from '../state/actions/auth';
import routes from '../constants/routes';

export default function Link() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    (async () => {
      try {
        if (params.has('token')) {
          const token = params.get('token');
          const point = params.get('point');
          const response = await start(kenteiRedirect, { token }, dispatch) as { id?: number | string | null };
          if (response && typeof response === 'object' && 'id' in response && response.id !== undefined && response.id !== null) {
            if (point && point in routes) {
              const id = response.id.toString();
              const route = routes[point as keyof typeof routes].replace(/:id\b/, id);
              navigate(route);
            }
          }
        }
      } catch (error) {
        history(routes.notFound);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
}
