import * as yup from 'yup';
import moment from 'moment';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  groupId: string | null;
  licenseCode: string;
  purchaseType: string;
  availableDeviceNumber: number;
  startDate: string;
  endDate: string;
  isInvalid: boolean;
  comment: string;
};

export const schemaEdit = yup.object({
  groupId: yup.string().typeError('muserCreate.msg.requiredGroup').required('muserCreate.msg.requiredGroup'),
  availableDeviceNumber: yup
    .number()
    .typeError('licenseCreate.msg.availableDeviceNumber')
    .required('licenseCreate.msg.availableDeviceNumber')
    .max(100, 'licenseCreate.msg.availableDeviceNumber')
    .min(0, 'licenseCreate.msg.availableDeviceNumber'),
  startDate: yup
    .date()
    .typeError('licenseCreate.msg.formatDate')
    .required('licenseCreate.msg.requiredStartDate')
    .min('2000/01/01', 'licenseCreate.msg.formatDate')
    .max('2999/12/31', 'licenseCreate.msg.formatDate'),
  endDate: yup
    .date()
    .typeError('licenseCreate.msg.formatDate')
    .required('licenseCreate.msg.requiredEndDate')
    .min('2000/01/01', 'licenseCreate.msg.formatDate')
    .max('2999/12/31', 'licenseCreate.msg.formatDate'),
});

export const schemaNew = schemaEdit;

export type Column = TableColumn & {
  id:
    | 'licenseId'
    | 'groupNameWithCode'
    | 'licenseCode'
    | 'availableDeviceNumber'
    | 'remainingDeviceNumber'
    | 'startDate'
    | 'endDate'
    | 'isInvalid'
    | 'licenseStatus'
    | 'renewFlag'
    | 'paymentMethod'
    | 'mailLicense'
    | 'createdAt';
};

export const columns: Column[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'remainingDeviceNumber',
    label: 'common.label.remainingDeviceNumber',
    align: 'center',
  },
  {
    id: 'createdAt',
    label: 'common.label.registerDate',
    align: 'center',
  },
  {
    id: 'startDate',
    label: 'common.label.startDate',
    format: (value: Date) => moment.utc(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    align: 'center',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'licenseStatus',
    label: 'common.label.licenseStatus',
    align: 'center',
  },
  {
    id: 'renewFlag',
    label: 'common.title.renewFlag',
    align: 'center',
  },
  {
    id: 'paymentMethod',
    label: 'stripeTransactions.label.paymnentMethod',
    align: 'center',
  },
  {
    id: 'mailLicense',
    label: 'common.label.checkbox',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  groupId: string | null;
  licenseCode: string;
  purchaseType: string;
  isIncludeExpired: boolean;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  groupId: '',
  licenseCode: '',
  purchaseType: '',
  isIncludeExpired: true,
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};
